
.root {
    padding: var(--spacingMd);
    color: var(--errorRed);
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
