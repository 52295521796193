
.formHeader {
    color: var(--textDarkGray);
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: var(--spacingSm);
}

.formPrompt {
    margin-bottom: var(--spacingXl);
}

@media (min-width: 1000px) {
    .formContent {
        padding: 0 4em;
    }
}
