
.starsContainer {
    display: flex;
    gap: var(--spacingLg);

    .starIcon {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        

        &.selected {
            color: yellow;
            transform: scale(1.2);
            text-shadow: 0px 0px 1px black, 0px 0px 1px black;
        }
    }
}

.inputSubtext {
    position: absolute;
    font-size: var(--fontSizeSm);
    top: -1.1rem;
}
