
.root {
    padding: var(--spacingLg) var(--spacingXl) 0 var(--spacingXl);
    text-align: center;
    font-size: 0.6rem;
    color: var(--textDarkestGray);

    .importantSafetyInfo {
        // font-size: 0.7rem;
        text-transform: uppercase;
        font-weight: bolder;
    }
}

@media (max-width: 350px) {
    .root {
        padding: var(--spacingMd) var(--spacingLg) 0 var(--spacingLg);
        font-size: 0.5rem;
    }
}
