
.formHeader {
    color: var(--textDarkGray);
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: var(--spacingSm);
}

.formPrompt {
    margin-bottom: var(--spacingMd);
}

.patientTiles {
    display: flex;
    gap: var(--spacingLg);
    overflow-x: auto;
}

@media (min-width: 1000px) {
    .formContent {
        padding: 0 4em;
    }
}
