
.couponLibraryPageContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 100%;

    .banner {
        background-color: var(--greenlineLightTransparent);
        color: white;
        display: flex;
        align-items: center;
        padding: var(--spacingMd) var(--spacingLg);
        flex: none;
        font-weight: bold;

        &.bigPadding {
            padding: var(--spacingLg) var(--spacingXl);
        }

        .addPetPrompt {
            text-align: left;
        }

        .patientName {
            font-weight: bold;
        }
    }

    .mainContent {
        flex: 1;
        padding: var(--spacingXl);
        display: flex;
        flex-direction: column;

        .featuredOffersContainer {
            width: fit-content;
            align-self: center;
        }

        .subheader {
            align-items: center;
            margin-bottom: var(--spacingXl);
            position: relative;

            .subheaderText {
                flex: 1;
                font-size: 1.2rem;
                color: var(--textDarkGray);
                pointer-events: none;

                &.absoluteCentered {
                    position: absolute;
                    text-align: center;
                    width: 100%;
                }
            }

            .backButton {
                &.hidden {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        .somethingElseText {
            margin-top: var(--spacingLg);
            text-decoration: none;
        }
    }
}

@media (max-width: 350px) {
    .couponLibraryPageContent {
        .mainContent {
            padding: var(--spacingMd);

            .subheader {
                .subheaderText {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (min-width: 750px) {
    .couponLibraryPageContent {
        .mainContent {
            .subheader {
                .subheaderText {
                    font-size: 1.5rem;
                    position: absolute;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width: 1000px) {
    .couponLibraryPageContent {
        .mainContent {
            .subheader {
                margin-bottom: 3em;

                .subheaderText {
                    font-size: 2rem;
                }
            }

            .accordionContainer {
                padding: 0 5em;
            }

            .somethingElseText {
                margin-top: 3em;
            }
        }

        .featuredOffersContainer {
            .featuredOffersText {
                font-size: var(--fontSizeLg);
                margin-bottom: var(--spacingMd);
            }
        }
    }
}
