
.tileText {
    font-size: 1.2rem;
}

.small {
    font-size: 0.6rem;
}

.selected {
    i {
        animation: bounce 0.4s cubic-bezier(0.5, 0.05, 1, 0.5);
        animation-direction: alternate;
        animation-iteration-count: infinite;
    }
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 0.33rem, 0);
    }
}
  
@-webkit-keyframes bounce {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0.33rem, 0);
        transform: translate3d(0, 0.33rem, 0);
    }
}

@media (min-width: 1000px) {
    .root {
        padding: var(--spacingMd) var(--spacingMega);
        border-radius: 0.6em;
    }
}
