
.accordionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.groupDescriptionItem {
    white-space: nowrap;
    font-size: 0.85rem;

    .and {
        padding: 0 var(--spacingSm);
    }

    .productNameSubtext {
        position: absolute;
        font-size: 0.5rem;
    }
}

.numberOfAvailableOffers {
    padding-right: var(--spacingSm);
    flex: none;
    font-size: 0.7rem;
    font-style: italic;
    color: var(--textGray);
}

@media (max-width: 435px) {
    .accordionHeader {
        flex-direction: column;
        align-items: flex-start;

        .numberOfAvailableOffers {
            padding-top: var(--spacingLg);
        }
    }
}

@media (max-width: 300px) {
    .groupDescriptionItem {
        font-size: 0.6rem;

        .productNameSubtext {
            font-size: 0.4rem;
        }
    }
}
