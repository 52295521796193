
.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .wheelImg {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .wheelContainer {
    width: 300px;
    height: 300px;
    display: flex;
  }

  .animate {
    .wheelImg {
      transition: transform 3s ease-in-out;
    }
  }
}
