
.root {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-position-y: bottom;
}

@media (min-width: 650px) {
    .root {
        background-size: auto;
        background-position-x: right;
    }
}
