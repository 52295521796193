
.root {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }

    .modalContainer {
        position: relative;
        width: 95%;
        background-color: white;
        border-radius: var(--borderRadiusMd);
        text-align: left;
        padding: var(--spacingLg);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);

        &.custom {
            padding: initial;
            background-color: initial;
            text-align: initial;
            overflow: hidden;
        }

        .content {
            margin: var(--spacingMd);
            color: var(--textDarkGray);
        }

        .closeButtonIcon {
            position: absolute;
            top: var(--spacingMd);
            right: var(--spacingMd);
            border: 1px solid var(--textDarkGray);
            color: var(--textDarkGray);
            border-radius: 50%;
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            i {
                font-size: 0.75rem;
            }
        }
    }
}

@media (min-width: 1280px) {
    .root {
        .modalContainer {
            width: 50%;
        }
    }
}
