
.dashboardPageContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 100%;

    .banner {
        background-color: var(--greenlineLightTransparent);
        color: white;
        display: flex;
        align-items: center;
        height: 70px;
        padding: 0 var(--spacingXl);
        flex: none;


        .choosePetPrompt {
            font-weight: bold;
            font-size: 1.125em;
        }

        .loadingBanner {
            flex: 1;
            font-weight: bold;
            text-align: center;
            font-size: 1.5em;
        }
    }

    .welcomePrompt {
        text-align: center;
        color: var(--greenlineMediumGreen);
        font-size: 1.5rem;
    }

    .welcomeFullName {
        text-align: center;
        color: var(--greenlineMediumGreen);
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.1;
        padding-bottom: var(--spacingLg);
    }

    .spinnerWheelIntro {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-top: clamp(10%, 40px, 25%);
    }

    .patientTiles {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: var(--spacingMega) var(--spacingLg);
        gap: var(--spacingLg);
        overflow-y: auto;
    }
}

@media (min-width: 1000px) {
    .dashboardPageContent {
        justify-content: start;

        .patientTiles {
            margin-top: var(--spacingMega);
            flex: none;
            gap: var(--spacingMega);
        }


        .welcomePrompt {
            font-size: 2rem;
        }

        .welcomeFullName {
            font-size: 2.625rem;
        }
    }
}
