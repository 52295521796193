
.root {
    $darkBlue: rgb(50, 59, 122);
    $contentMaxWidth: 32rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: var(--spacingXl);
    color: $darkBlue;

    .backToOffersButton {
        align-self: flex-start;
    }

    .offerDetailsContainer {
        background-color: $darkBlue;
        $topLayerOffset: 1.2rem;

        position: relative;
        width: calc(100% - $topLayerOffset);
        max-width: $contentMaxWidth;
        margin-top: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-right: $topLayerOffset;

        .offerDetails {
            width: 100%;
            background-color: rgb(238, 238, 238);
            border-top: 1px solid rgba(0, 0, 0, 0.01);
            box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.6rem;
            padding: 4rem var(--spacingLg);
            font-weight: bolder;
            overflow-y: auto;
            transform: translate($topLayerOffset, -$topLayerOffset);

            .offerText {
                transition: all 0.25s ease-in-out;

                &.blurry {
                    filter: blur(0.2rem);
                }
            }
        }
    }

    .offerFooter {
        font-size: 0.7rem;
        font-weight: bold;
        margin-top: var(--spacingLg);
        padding-bottom: var(--spacingLg);
        max-width: $contentMaxWidth;
        align-self: center;
        transition: all 0.25s ease-in-out;

        &.blurry {
            filter: blur(0.2rem);
        }
    }

    .showSelectedOfferOverlay {
        color: white;
        text-align: center;
        padding: var(--spacingMega) 3em;
        margin: 0 -1em;
        background-size: 120%;
        background-repeat: no-repeat;
        background-position: center;
        font-weight: bold;

        .prompt {
            margin-bottom: var(--spacingLg);
        }
    }
}

@media (min-width: 500px) {
    .root {
        .offerDetailsContainer {
            .offerDetails {
                .offerText {
                    font-size: 1.1rem;
                    padding: 0 2em;

                    &.blurry {
                        filter: blur(0.4rem);
                    }
                }
            }
        }

        .offerFooter {
            margin-top: var(--spacingLg);
            padding-bottom: var(--spacingLg);
            padding: 0 var(--spacingMega) var(--spacingLg) var(--spacingMega);

            &.blurry {
                filter: blur(0.4rem);
            }
        }
    }
}

@media (max-width: 500px) {
    .offerText {
        font-size: 1rem;
    }
}

@media (max-width: 350px) {
    .root {
        .offerDetailsContainer {
            .offerDetails {
                padding: var(--spacingMega) var(--spacingLg);

                .offerText {
                    font-size: 0.75rem;
                }
            }
        }

        .offerFooter {
            font-size: 0.6rem;
        }
    }
}

@media (min-width: 1000px) {
    .root {
        .offerDetailsContainer {
            margin-top: 8em;
        }

        .offerFooter {
            padding: var(--spacingLg) 0 0 0;
        }

        .showSelectedOfferOverlay {
            font-size: 1.75em;

        }
    }
}

@media (min-width: 1280px) {
    .root {
        .offerDetailsContainer {
            margin-top: 10em;
        }
    }
}

@media (min-width: 1440px) {
    .root {
        .offerDetailsContainer {
            margin-top: 14em;
        }
    }
}
