
.loadingSpinnerRoot {
    $spinnerTimingFunction: cubic-bezier(.22, .31, .75, .54);
    $spinnerAnimationDuration: 1.5s;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    opacity: 0;
    animation: fadeIn 0.3s ease-in-out forwards;

    .spinner {
        font-size: var(--fontSizeMega);
        -webkit-animation: spin $spinnerAnimationDuration $spinnerTimingFunction infinite;
        -moz-animation: spin $spinnerAnimationDuration $spinnerTimingFunction infinite;
        animation: spin $spinnerAnimationDuration $spinnerTimingFunction infinite;
    }

    .message {
        font-weight: bold;
    }
}

@-moz-keyframes fadeIn {
    100% {
        opacity: 1;
    } 
}

@-webkit-keyframes fadeIn { 
    100% {
        opacity: 1;
    } 
}

@keyframes fadeIn { 
    100% { 
        opacity: 1;
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    } 
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    } 
}

@keyframes spin {
    100% { 
        transform: rotate(360deg);
    }
}
