
.root {
    border-top: 1px solid var(--greenlineLight);
    border-bottom: 1px solid var(--greenlineLight);
    padding: var(--spacingMd) 0;
    flex: none;

    .logo {
        height: 3.25rem;
        width: auto;
    }
}
