:root {
  --greenlineDark: rgb(49, 125, 61);
  --greenlineMediumGreen: rgb(60, 129, 58);
  --greenlineMediumGreenTransparent: rgba(73, 160, 70, 0.7);
  --greenlineMediumGreenTransparent2: rgba(73, 160, 70, 0.9);
  --greenlineLight: rgb(166, 206, 67);
  --greenlineLightTransparent: rgba(167, 206, 67, 0.7);
  --greenlineBlue: rgb(158, 197, 238);

  --errorRed: #BB0000;
  --lightGray: rgb(248, 248, 248);
  --gray: rgb(144, 155, 163);

  --spacingSm: 0.25rem;
  --spacingMd: 0.5rem;
  --spacingLg: 1rem;
  --spacingXl: 1.5rem;
  --spacingMega: 2rem;

  --fontSizeSm: 0.75rem;
  --fontSizeMd: 1rem;
  --fontSizeLg: 1.5rem;
  --fontSizeXl: 2rem;
  --fontSizeMega: 4rem;

  --borderRadiusSm: 0.25rem;
  --borderRadiusMd: 0.5rem;
  --borderRadiusLg: 1rem;
  --borderRadiusXl: 1.5rem;

  --textGray: #a0a0a0;
  --textDarkGray: #7d7d7d;
  --textDarkestGray: rgb(68, 68, 68);
}

html, body, .appRoot, #root {
  width: 100%;
  height: 100%;
  background-color: var(--lightGray);
}

.appRoot {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--textDarkestGray);
  overflow: hidden;
}

.pageContentContainer {
  flex: 1;
  overflow: auto;
  display: flex;
  background-color: rgb(207, 207, 207);
}

.pageContent {
  flex: 1;
  background-color: white;
  min-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5 {
  color: var(--greenlineMediumGreen);
}

.link {
  color: var(--greenlineDark);
  text-decoration: underline;
  cursor: pointer;
}

.padding-sm {
  padding: var(--spacingSm);
}

.padding-md {
  padding: var(--spacingMd);
}

.padding-lg {
  padding: var(--spacingLg);
}

.padding-xl {
  padding: var(--spacingXl);
}

.padding-top-bottom-xl {
  padding: var(--spacingXl) 0;
}

.margin-top-md {
  margin-top: var(--spacingMd);
}

.margin-top-lg {
  margin-top: var(--spacingLg);
}

.text-sm {
  font-size: var(--fontSizeSm);
}

.text-md {
  font-size: var(--fontSizeMd);
}

.text-lg {
  font-size: var(--fontSizeLg);
}

.text-xl {
  font-size: var(--fontSizeXl);
}

.text-italic {
  font-style: italic;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-dark-gray {
  color: var(--textDarkGray);
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.position-relative {
  position: relative;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.spaced-content-vertical > *:not(:last-child) {
  /* Gives all form elements except the last one margin-bottom */
  margin-bottom: var(--spacingMd);
}

/* Form styles */

.form > *:not(:last-child) {
  /* Gives all form elements except the last one margin-bottom */
  margin-bottom: var(--spacingXl);
}

.form input, select, textarea {
  border: 1px solid var(--gray);
  border-radius: var(--borderRadiusSm);
  outline: none;
  padding: var(--spacingMd);
  width: 100%;
  background-color: var(--lightGray);
  color: var(--textDarkestGray);
}

.form input.fancy, select.fancy {
  background-color: rgb(225, 234, 226);
  border-color: var(--greenlineLight);
}

.form input.error, select.error, textarea.error {
  background-color: rgba(255, 0, 0, 0.107);
  border-color: var(--errorRed);
}

.form input::placeholder, textarea::placeholder {
  color: var(--textGray);
  font-size: 0.8rem;
}


/* Button styles */

.et-btn {
  border: none;
  border-radius: var(--borderRadiusLg);
  padding: var(--spacingMd) var(--spacingMega);
  color: white;
  background-color: var(--greenlineMediumGreen);
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
}

.et-btn.small {
  padding: var(--spacingSm) var(--spacingLg);
}

.et-btn:disabled {
  background-color: rgb(126, 182, 124);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}

.et-btn.basic {
  background-color: white;
  color: var(--textDarkestGray);
  border: 1px solid var(--greenlineBlue);
  box-shadow: none;
}

/* Accordion styles */

.accordion-button {
  border-bottom: 2px solid var(--greenlineDark) !important;
  padding-left: var(--spacingMd) !important;
  padding-right: var(--spacingMd) !important;
  background-color: transparent !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button::after {
  background-size: 0.8rem !important;
  background-position: center !important;
}

.accordion .card-header button{
  border: none !important;
}

.accordion-item {
  background-color: transparent !important;
}

@media (max-width: 350px) {
  .et-btn {
    padding: var(--spacingSm) var(--spacingLg);
  }
}

@media (min-width: 1000px) {
  .et-btn {
    padding: var(--spacingMd) 4em;
    border-radius: var(--borderRadiusXl);
  }

  .pageContent {
    margin: 0 11vw;
  }

  .text-sm {
    font-size: var(--fontSizeMd);
  }
}
