
.errorMessage {
    color: var(--errorRed);
    font-weight: bold;
    position: absolute;
    top: -2em;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    font-size: 0.9em;
}

.adImage {
    width: 100%;
    align-self: center;
}

.formContainer {
    width: 100%;
    align-self: center;
}

@media (min-width: 1000px) {
    .adImage {
        width: 56vw;
        max-width: 56em;
    }

    .formContainer {
        width: 24rem;
        margin-top: var(--spacingMega);


        .header {
            white-space: nowrap;
        }
    }
}
